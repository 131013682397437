.service-priority {
  padding: 0.3rem 0.6rem;
  font-size: 0.8rem;
  outline: none;
}
.filterTable-section {
  margin: 1rem 0px;
}
.service-modal {
  max-width: 600px;
  margin: 0 auto;
}
.service-header-modal {
  font-size: 1.5rem;
  margin: 0.7rem 0px 0.4rem 0px;
  font-weight: bolder;
}
.service-modal-img {
  width: 100%;
  margin: auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }
}
.service-des-modal {
  max-width: 600px;
  margin: 0.7rem 0px 0.9rem 0px;
  letter-spacing: 0.5px;
}
.service-time {
  margin-bottom: 1.3rem;
}
.service-header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-close {
  cursor: pointer;
  font-size: 1.4rem;
  svg {
    color: $red;
  }
  &:hover {
    svg {
      color: lighten($red, 10%);
    }
  }
}
