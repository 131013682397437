#root .ql-container {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: #fefcfc;
  height: 75vh;
}

/* Snow Theme */
#root .ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

#root .ql-editor {
  min-height: 18em;
  max-height: 75vh;
  overflow-y: auto;
}
